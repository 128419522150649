import {
  resetAffectedField,
  useApiApproveFields,
  type ILeaseDocumentField,
  type ILeaseSpecialCondition,
} from '@register'
import type { MaybeRef } from '~/types/helpers'

export const useApproveFields = (sectionId?: MaybeRef<string>) => {
  // GLOBAL
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  const {
    lease,
    fields: allFields,
    specialConditionFields,
    clausesAndDefinitions,
    getSection,
  } = useCurrentLeaseDocument()

  // COMPUTED
  const _sectionId = computed(() => toValue(sectionId))
  const fields = computed<(ILeaseDocumentField | ILeaseSpecialCondition)[]>(
    () => {
      if (!_sectionId.value) return []

      if (_sectionId.value === 'SpecialConditions') {
        return specialConditionFields.value
      }

      if (_sectionId.value?.startsWith('ClausesAndDefinitions-')) {
        const id = _sectionId.value.split('-')[1]
        const clause = clausesAndDefinitions.value[id]
        const fields = []

        if (clause?.clause) {
          fields.push(clause.clause)
        }
        if (clause?.definition) {
          fields.push(clause.definition)
        }

        return fields
      }

      return getSection(_sectionId.value)?.fields ?? []
    },
  )
  const fieldIds = computed(() => fields.value.map((field) => field.id!))
  const allFieldIds = computed(() => {
    const _fields = [
      ...allFields.value,
      ...(lease.value.clausesAndDefinitions.table ?? []),
      ...specialConditionFields.value,
    ]
    return _fields.map((field) => field.id!)
  })
  const isAllApproved = computed(() => {
    const approvedFields = _sectionId.value
      ? fields.value.filter((field) => {
          if (isLeaseSpecialCondition(field)) return !!field.isApproved
          return !!field.value?.isApproved
        }).length
      : lease.value?.summary?.approvedFields ?? 0
    const totalFields = _sectionId.value
      ? fields.value.length
      : lease.value?.summary?.totalFields ?? 0
    return approvedFields / totalFields === 1
  })

  // SUBMIT
  const { mutateAsync: approveAll } = useApiApproveFields(divisionId, leaseId)
  const submitHandler = useConfirmHandler(
    () => {
      if (isAllApproved.value) {
        if (_sectionId.value)
          return approveAll({ unapproveFields: fieldIds.value })
        return approveAll({ unapproveAll: true })
      }

      if (_sectionId.value) return approveAll({ approveFields: fieldIds.value })
      return approveAll({ approveAll: true })
    },
    {
      modal: {
        title: computed(() => {
          const action = isAllApproved.value ? 'unapprove' : 'approve'
          return `Are you sure you want ${action} all fields?`
        }),
        body: computed(() => {
          const opposite = isAllApproved.value ? 'approved' : 'unapproved'
          const action = isAllApproved.value ? 'unapproved' : 'approved'
          return `All your ${opposite} fields will be marked as ${action}.`
        }),
        confirmInputText: computed(() => {
          if (_sectionId.value) return undefined
          return isAllApproved.value ? 'UNAPPROVE' : 'APPROVE'
        }),
      },
      loadingMessage: computed(() => {
        const action = isAllApproved.value ? 'Unapproving' : 'Approving'
        return `${action} all fields...`
      }),
      successMessage: computed(() => {
        const action = isAllApproved.value ? 'unapproved' : 'approved'
        return `All fields have been ${action}.`
      }),
      errorMessage: computed(() => {
        const action = isAllApproved.value ? 'unapprove' : 'approve'
        return `Failed to ${action} all fields.`
      }),
      submitHandlerOptions: {
        onSuccess: () => {
          const _fieldIds = _sectionId.value
            ? fieldIds.value
            : allFieldIds.value

          for (const fieldId of _fieldIds) {
            resetAffectedField(fieldId)
          }
        },
      },
    },
  )

  return {
    isAllApproved,
    submitHandler,
  }
}

function isLeaseSpecialCondition(
  field: ILeaseDocumentField | ILeaseSpecialCondition,
): field is ILeaseSpecialCondition {
  return 'isApproved' in field
}
