import { getNode } from '@formkit/core'
import { useRequestCounter } from '~/composables/useRequestCounter'
import { toast as Vue3Toastify } from 'vue3-toastify'

export const useLeaseReviewLeaseSubmit = () => {
  /* === LEASE & DIVISION === */
  const divisionId = getCurrentDivisionId()
  const { lease } = useCurrentLeaseDocument()
  /* === LEASE & DIVISION === */

  /* === MUTATION === */
  const { mutateAsync: saveLease } = useApiUpdateLeaseDocument(
    divisionId,
    lease.value.id,
  )
  /* === MUTATION === */

  const status = ref(lease.value.status)
  const sector = ref(lease.value.sector)

  const { increment, decrement } = useRequestCounter('lease')
  const toast = useToast({
    position: Vue3Toastify.POSITION.BOTTOM_RIGHT,
  })

  const submit = async (): Promise<void> => {
    // Increment request counter
    increment()

    saveLease({ status: status.value, sector: sector.value })
      .catch((error) => {
        if (isAbortError(error)) return

        toast.error('Error saving')

        for (const key of error?.data?.errors ?? []) {
          if (!key) return
          const node = getNode(key)
          if (!node) continue
          node.setErrors([error.data.errors[key].detail])
        }
      })
      .finally(() => {
        // Decrement request counter
        decrement()
      })
  }

  watch([status, sector], () => submit())

  return { status, sector }
}
