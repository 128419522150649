import { storeToRefs } from 'pinia'
import { useLeaseStore } from '@register'

export const useFastApproval = () => {
  const { fastApproval } = storeToRefs(useLeaseStore())

  return {
    fastApproval,
  }
}
