import type {
  ILeaseDocumentField,
  ILeaseDocumentFieldGroup,
  ILeaseSpecialCondition,
} from '@register'
import type { ISidebarItem, ISidebarSection } from '@ui/components/Sidebar'

export interface LeaseReviewSidebarSection extends ISidebarSection {
  fields?: (ILeaseDocumentField | ILeaseSpecialCondition)[]
  items: LeaseReviewSidebarItem[]
  description?: string
}

export interface LeaseReviewSidebarItem extends ISidebarItem {
  mandatory?: boolean
  description?: string
  fields?: (ILeaseDocumentField | ILeaseSpecialCondition)[]
}

export const useReviewSidebarSections = () => {
  const { groups, clausesAndDefinitions, specialConditionFields, lease } =
    useCurrentLeaseDocument()

  return computed<LeaseReviewSidebarSection[]>(() => {
    const sections: LeaseReviewSidebarSection[] = groups.value.map((group) => {
      return {
        id: group.id,
        title: group.name,
        description: group.description,
        fields: getFieldsByGroup(group),
        items: (group.sections ?? []).map((section) => {
          return {
            id: section.id,
            title: section.name,
            description: section.description,
            icon: 'review/' + section.id.replace(/(?:^|_)[a-z0-9_]+(?=[A-Z])/g, ''),
            mandatory: section.fields?.some((field) => field.isRequired),
            fields: section.fields ?? [],
          }
        }),
      }
    })

    if (clausesAndDefinitions?.value) {
      // Clauses and Definitions
      sections.push({
        id: 'ClausesAndDefinitions',
        title: 'Clauses and Definitions',
        fields: lease.value?.clausesAndDefinitions?.table ?? [],
        items: Object.entries(clausesAndDefinitions?.value ?? []).map(
          ([id, clause]) => {
            const fields: ILeaseDocumentField[] = []

            if (clause.clause) fields.push(clause.clause)
            if (clause.definition) fields.push(clause.definition)

            return {
              id: 'ClausesAndDefinitions-' + id,
              title: clause.name,
              icon: 'review/' + id.replace(/(?:^|_)[a-z0-9_]+(?=[A-Z])/g, ''),
              fields,
            }
          },
        ),
      })
    }
    // TODO: on and off special conditions
    if (clausesAndDefinitions?.value && lease.value?.specialConditions) {
      // Special Conditions
      sections.push({
        id: 'SpecialConditions',
        title: 'Special Conditions',
        items: [
          {
            id: 'SpecialConditions',
            title: 'Special Conditions',
            icon: 'review/SpecialConditions',
          },
        ],
        fields: specialConditionFields.value,
      })
    }

    return sections
  })

  function getFieldsByGroup(group: ILeaseDocumentFieldGroup) {
    return (group.sections ?? []).flatMap(({ fields }) => fields ?? [])
  }
}
