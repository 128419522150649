<script lang="ts" setup>
interface Props {
  publishing?: boolean
  autoSaving?: boolean
}
withDefaults(defineProps<Props>(), {
  publishing: false,
  autoSaving: false,
})

const emits = defineEmits(['publish'])
const { lease } = useCurrentLeaseDocument()
</script>

<template>
  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-from-class="translate-y-20"
    enter-to-class="translate-y-0"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="translate-y-0 "
    leave-to-class="translate-y-20"
  >
  </transition>

  <transition
    enter-active-class="transition duration-200 ease-out"
    enter-from-class="translate-y-20"
    enter-to-class="translate-y-0"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="translate-y-0 "
    leave-to-class="translate-y-20"
  >
    <div
      v-if="!lease?.isPublished"
      class="bg-gray-750/90 pb-safe-bottom absolute bottom-0 left-0 z-[105] w-full border-t border-t-gray-700 shadow-[0_-4px_30px_0px_rgba(0,0,0,0.35)] backdrop-blur-2xl backdrop-saturate-[180%]"
    >
      <div class="flex w-full justify-between px-4 py-4">
        <div class="flex justify-end space-x-4">
          <div v-if="autoSaving" class="self-center">
            <Spinner class="fill-primary max-w-[20px]" />
            Saving changes...
          </div>
        </div>

        <div class="flex justify-end">
          <Tooltip
            v-if="!lease?.isPublished"
            placement="left"
            content="Move this lease to the portfolio page"
            class="show-2s min-w-max bg-gray-950 text-xs font-medium text-white"
          >
            <Button
              :loading="publishing"
              color="secondary"
              @click.prevent="emits('publish')"
            >
              Add to portfolio
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.show-2s {
  @apply opacity-0;
  animation: 500ms show-after-2s 500ms forwards;
}

@keyframes show-after-2s {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
</style>
