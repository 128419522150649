import { MobileView, useMobileViewStore } from '@register'

export interface UseLeaseSectionTabsOptions {
  scrollOnMount?: boolean
}

export const useLeaseSectionTabs = (
  options: UseLeaseSectionTabsOptions = {},
) => {
  const { scrollOnMount = false } = options

  const store = useMobileViewStore()

  const { sections, getGroupBySectionId } = useCurrentLeaseDocument()
  const bus = useEventBus<string>('open:sidebarSection')

  const defaultValue = () => sections.value[0]?.id
  const active = useRouteQuery('section', defaultValue, {
    mode: 'push',
  })
  const isActive = (sectionId: string) => active.value === sectionId

  // Change the active item in the sidebar
  const change = (sectionId: string) => {
    active.value = sectionId
    store.setViewOnMobile(MobileView.FIELDS)
  }

  // Scroll to the top of the active item in the sidebar
  const scrollTo = (sectionId = active.value) => {
    let groupId: string | undefined

    if (sectionId.split('-')[0] === 'ClausesAndDefinitions') {
      groupId = 'ClausesAndDefinitions'
    } else {
      groupId = getGroupBySectionId(sectionId)?.id
    }

    if (!groupId) return

    const id = 'section-' + groupId
    // Open section
    bus.emit(id)
    // TODO: Scroll to item instead of section if the item is not visible
    const element = document.getElementById(id)

    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  }

  // Scroll on mounted
  if (scrollOnMount) {
    onMounted(() => {
      // Don't scroll if the default value is active, which is the first item
      if (active.value === defaultValue()) return
      scrollTo()
    })
  }

  return {
    active,
    isActive,
    change,
    scrollTo,
  }
}
