import type { Ref } from 'vue'
import type { ILeaseDocumentField, ILeaseSpecialCondition } from '@register'

export const useFormProgress = (
  fields: Ref<(ILeaseDocumentField | ILeaseSpecialCondition)[]>,
) => {
  const count = computed(
    () =>
      fields.value.filter((f) => {
        if (isLeaseSpecialCondition(f)) return f.isApproved
        return !!f?.value?.isApproved
      }).length,
  )
  const total = computed(() => fields.value.length)
  const progress = computed(() => Math.round((count.value / total.value) * 100))

  return {
    count,
    total,
    progress,
  }
}

function isLeaseSpecialCondition(
  field: ILeaseDocumentField | ILeaseSpecialCondition,
): field is ILeaseSpecialCondition {
  return 'isApproved' in field
}
