<script setup lang="ts">
import {
  useLeaseReviewLeaseSubmit,
  useLeaseReviewPublish,
} from '~/components/LeaseReview/composables'
import { useLeaseReviewWebViewer } from '~/components/LeaseReview/composables'
import { storeToRefs } from 'pinia'
import { useApproveFields, useLeaseStore } from '@register'
import { openNotesModal } from '~/components/NotesModal/composables/useNotesModal'
import { IEntity } from '~/composables/useAPI/types'
import { MenuItem } from '@headlessui/vue'

// LEASE DOCUMENT
const {
  documentName,
  lease,
  sections,
  clausesAndDefinitions,
  specialConditions,
} = useCurrentLeaseDocument()

// PAGE TITLE & ANALYTICS
useHead(() => ({
  title: documentName.value ? `Lease: ${documentName.value}` : 'Lease',
}))
usePageAnalytics('Lease: Review')

// GLOBAL
const route = useRoute()

// RESET DOCUMENT ON CREATED
const { resetDocument } = useLeaseReviewWebViewer()

resetDocument()
// PUBLISH

const { onPublish, isPublishing } = useLeaseReviewPublish()
// UTILS
const isClausesAndDefinitions = (id: string) => {
  return id.startsWith('ClausesAndDefinitions')
}
const parseID = (id: string) => {
  const [_, _id] = id.split('-')
  return _id
}
const parseClauseAndDefinition = (id: string) => {
  return clausesAndDefinitions.value[parseID(id)]!
}
// HEADER
const { status, sector } = useLeaseReviewLeaseSubmit()
const { showProgress } = storeToRefs(useLeaseStore())
const { isAllApproved, submitHandler: onApproveAll } = useApproveFields(
  computed(() => (route.query.section ?? sections.value[0]?.id) as string),
)
</script>

<template>
  <div class="w-full">
    <LeaseReviewSectionTabs>
      <template #header>
        <div
          ref="page"
          class="bg-gray-850 shadow-gray-850 sticky top-0 z-[100] -mx-4 -mr-2 bg-gray-900/50 px-4 py-2 shadow-sm backdrop-blur-2xl backdrop-saturate-[180%] transition-shadow"
        >
          <div class="flex flex-row items-center gap-2">
            <LeaseReviewDocumentName />

            <LeaseReviewFormProgress v-if="showProgress" class="max-w-20" />
          </div>
        </div>

        <ReviewLayoutHeader
          v-model:status="status"
          v-model:sector="sector"
          @click:notes="openNotesModal(IEntity.LEASE, lease.id)"
        >
          <template #actions>
            <MenuItem v-slot="{ active }">
              <Button
                :color="active ? 'danger' : 'transparentDanger'"
                class="justify-start gap-2 whitespace-nowrap rounded-none rounded-b"
                icon="approve-all"
                @click="onApproveAll"
              >
                {{ isAllApproved ? 'Unapprove' : 'Approve' }} all fields for
                this section
              </Button>
            </MenuItem>
          </template>
        </ReviewLayoutHeader>
      </template>

      <template #default="{ active, section }">
        <LazyLeaseReviewFastApprovalFields
          v-if="active === 'FastApproval'"
          class="mt-4"
        />
        <LazyLeaseReviewClausesAndDefinitions
          v-else-if="isClausesAndDefinitions(active)"
          :key="parseID(active)"
          class="mt-4"
          :clause-and-definition="parseClauseAndDefinition(active)"
        />
        <LazyLeaseReviewSpecialConditions
          v-else-if="active === 'SpecialConditions'"
          class="mt-4"
          :special-conditions="specialConditions"
        />
        <LazyLeaseReviewSectionFields
          v-else-if="section"
          class="z-20 mt-4"
          :section="section"
        />
      </template>
    </LeaseReviewSectionTabs>

    <LeaseReviewActions :publishing="isPublishing" @publish="onPublish" />
  </div>
</template>
