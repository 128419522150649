<script setup lang="ts">
import { useFastApproval } from '~/components/LeaseReview/composables/useFastApproval'

const { fastApproval } = useFastApproval()
const onChange = () => {
  useAnalytics({
    name: 'Lease Review: Toggle Mandatory Fields',
    showDivision: true,
    data: {
      'Mandatory Fields Enabled': fastApproval.value,
    },
  })
}
</script>

<template>
  <FormKit
    v-model="fastApproval"
    name="fastApprovalToggle"
    type="toggle"
    label="Mandatory Fields (*)"
    label-class="!font-sm !text-gray-450"
    @change="onChange"
  />
</template>
