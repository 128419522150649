import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type {
  ILeaseRegisterLease,
  IPatchApprovedFieldsRequest,
} from '@register'

export const useApiApproveFields = (companyId: string, leaseId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (body: IPatchApprovedFieldsRequest) =>
      fetchAuthorized<ILeaseRegisterLease>(
        `/companies/${companyId}/leaseRegister/leases/${leaseId}/approvedFields`,
        {
          method: 'PATCH',
          body,
        },
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(['getLeaseDocumentId', companyId, leaseId], data)
      queryClient.invalidateQueries({
        queryKey: ['propertyLeases', 'company', companyId],
      })
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId],
      })
    },
  })
}
