import { useModal } from 'vue-final-modal'
import LeaseReviewConfirmModal from '~/components/LeaseReview/LeaseReviewConfirmModal.vue'
import { useRequestCounter } from '~/composables/useRequestCounter'
import { toast as Vue3Toasity } from 'vue3-toastify'

export const useLeaseReviewPublish = () => {
  /* === LEASE & DIVISION === */
  const divisionId = getCurrentDivisionId()
  const { lease, fields } = useCurrentLeaseDocument()
  /* === LEASE & DIVISION === */

  const { mutateAsync: saveLease, isPending: isPublishing } =
    useApiUpdateLeaseDocument(divisionId, lease.value.id)

  const { increment, decrement } = useRequestCounter('lease')
  const toast = useToast({
    position: Vue3Toasity.POSITION.BOTTOM_RIGHT,
  })

  // Submit handler
  const submit = () => {
    // Increment request counter
    increment()

    saveLease({ publish: true })
      .then(() => {
        toast.success('Lease published')
      })
      .catch((error) => {
        toast.error('Error publishing lease')
      })
      .finally(() => {
        // Decrement request counter
        decrement()
      })
  }

  // Confirmation modal when there are required fields that are not approved
  const { open: openConfirm, close: closeConfirm } = useModal({
    component: LeaseReviewConfirmModal,
    attrs: {
      onConfirm: () => {
        submit()
        closeConfirm()
      },
      onCancel: () => closeConfirm(),
    },
  })

  const onPublish = () => {
    if (!isValid()) {
      openConfirm()
      return
    }

    submit()
  }

  return {
    onPublish,
    isPublishing,
  }

  // Check if all required fields are approved
  function isValid() {
    return fields.value
      .filter((field) => field.isRequired)
      .every((field) => !!field.value?.isApproved)
  }
}
